
$enable-responsive-font-sizes: true;

  @import "~bootstrap/scss/bootstrap";

@include media-breakpoint-up(xs) {
  body {
    font-size: 1.5rem;
  }
  .btn {
    font-size: 1.5rem;
  }
}
@include media-breakpoint-up(sm) {
  body {
    font-size: 1.5rem;
  }
  .btn {
    font-size: 1.5rem;
  }
}
@include media-breakpoint-up(md) {
  body {
    font-size: 1rem;
  }
  .btn {
    font-size: 1rem;
  }
}
@include media-breakpoint-up(lg) {
  body {
    font-size: 1rem;
  }
  .btn {
    font-size: 1rem;
  }
}
@include media-breakpoint-up(xl) {
  body {
    font-size: 1rem;
  }
  .btn {
    font-size: 1rem;
  }
}

.saerp-kpi {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  padding: 1.2rem;
  margin: 0.25rem;
  min-width: 20rem;
}

.saerp-kpi-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  font-weight: 300;
  color: #000;
  font-family: 'Quicksand', 'Open sans', sans-serif;
}

.saerp-kpi-value {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 4em;
  color: #000;
  font-weight: 600;
  font-family: 'Quicksand', 'Open sans', sans-serif;
}