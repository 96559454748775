.tasks-today {
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
}

.tasks-week {
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(212,253,45,1) 100%);
}

.tasks-future {
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(238,45,253,0.7749474789915967) 100%);
}

.task-group {
  border-radius: 4px;
  margin-right: 1rem;
  margin-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  min-height: 70vh;
}

.button-eye {
  background: none;
  border: 0;
  font-size: larger;
}

.task-finshed {
  background: #95A5A6;
}

.task-alert {
  background: #CD6155;
}

.task-normal {
  background: #85C1E9;
}