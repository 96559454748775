.NotificationBar {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 0;
    width: 20vw;
    height: 100vh;
    border-left-width: 1px;
    border-left-color: #ccc;
    border-left-style: solid;
    padding-left: 20px;
    padding-top: 5rem;
    padding-right: 10px;
    z-index: 100;
    background-color: #eee;
}

.NotificationBar .title-segment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: solid 1px #aaa;
}

.NotificationBar .itens-segment {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
}