.Calendar {
    padding: 5px;
    font-size: smaller;
}
.Calendar .btn {
    padding: 2px;
}

.Calendar .Calendar-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
}