.Login {
  max-width: 500px;
  margin: auto;
  margin-top: 20vh;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.Login .logo {
  margin: auto;
  text-align: center;
}

.Login .button {
  margin-left: auto;
  margin-right: 0;
  padding-left: 35px;
  padding-right: 35px;
}

.Signup {
  max-width: 500px;
  margin: auto;
  margin-top: 20vh;
  padding: 15px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.Signup .logo {
  margin: auto;
  text-align: center;
}

.Signup .button {
  margin-left: auto;
  margin-right: 0;
  padding-left: 35px;
  padding-right: 35px;
}
