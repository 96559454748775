.App {
  min-height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.App .navbar-brand {
  font-family: "Comfortaa";
  color: "#fff";
}

.AppMain {
  display: flex;
  flex-direction: row;
}

.AppContainer {
  padding-top: 1rem;
  min-height: 100%;
  flex-grow: 14;
}

.Sidebar {
  min-width: 34ch;
  max-width: 50ch;
  min-height: 100vh;
  padding-left: 3ch;
  padding-top: 0.5rem;
  padding-right: 5ch;
  margin-right: 1ch;
  background-color: rgba(21, 67, 96, 0.7);
  flex-grow: 1;
  flex-direction: column;
  clip-path: polygon(0 0, 100% 0, 95% 100%, 0 100%);
}

.Sidebar .nav-link {
  color: rgba(246, 221, 204, 1);
  border-radius: 5px;
  margin: 0.25rem 0;
}

.Sidebar .nav-link:hover {
  background-color: rgba(246, 221, 204, 0.3);
}

.Sidebar .nav-link:active {
  background-color: rgba(246, 221, 204, 0.4);
}

.Sidebar .nav-link-active {
  color: #fff;
  background-color: rgba(246, 221, 204, 0.4);
}

.navitem {
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.navitem .menupill {
  font-size: smaller;
}

.sdcks-text {
  font-family: "Comfortaa";
  font-size: xx-large;
  font-weight: bold;
}
@media print {
  html {
    overflow-wrap: break-word;
  }
  .no-print {
    display: none;
  }
  .Sidebar {
    display: none;
  }
}

.LinesBackground {
  background-color: rgba(21, 67, 96, 0.7);
  clip-path: polygon(0 95%, 100% 40%, 100% 100%, 0 100%);
  width: 100vw;
  height: 36vh;
  bottom: 0;
  position: relative;
  z-index: -1;
}

.LinesBackground_2 {
  /* background-color: rgba(36, 113, 163, 0.4); */
  background-color: rgba(220, 118, 51, 0.7);
  clip-path: polygon(0 93%, 100% 30%, 100% calc(40% - 1px), 0 calc(93% + 5px));
  width: 100vw;
  height: 36vh;
  bottom: 0;
  position: relative;
  z-index: -1;
}

.LinesBackground_3 {
  background-color: rgba(246, 221, 204, 0.7);
  clip-path: polygon(0 92%, 100% 22%, 100% calc(30% - 1px), 0 calc(92% + 5px));
  width: 100vw;
  height: 36vh;
  bottom: 0;
  position: relative;
  z-index: -1;
}

.LinesBackground-container {
  position: fixed;
  display: block;
  overflow: hidden;
  bottom: 0;
  width: 100vw;
  z-index: -1;
  filter: drop-shadow(-1px 2px 1px rgba(50, 50, 0, 0.5));
}

.trialBar {
  top: 0;
  left: 0;
  background-color: #c55f49;
  text-align: center;
  width: 100vw;
  color: #ebf376;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
