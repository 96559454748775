
.plan-card {
  background-color: #eee;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.plan-card-header {
  font-family: "Comfortaa";
  font-size: larger;
  font-weight: bold;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #aaa;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.plan-card-body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
  min-height: 13rem;
}

.plan-card-price {
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  font-weight: bold;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.plan-card-selected {
  border-style: solid;
  border-width: 8px;
  border-color: #0b0;
}