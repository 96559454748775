.ObjSelection {
    width: 100%;
    padding: 5px;
    border: 1px solid #eee;
    border-radius: 5px;
}

.ObjSelection .ObjOpenBtn {
    margin-right: 10px;
    margin-left: 10px;
}