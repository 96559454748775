.NotaPromissoria {
  display: block;
  position: relative;
  width: 100%;
  height: 25rem;
  background-color: #F4D03F;
  padding: 0.7rem;
}

.block-avalista {
  transform: rotate(270deg);
  transform-origin: 0 0;
  display: block;
  position: absolute;
  bottom: -40%;
  left: 1%;
  width: 24rem;
  padding: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
  border-radius: 8px;
}

.texto-avalista {
  display: block;
  font-weight: bold;
}

.campo-fill {
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #000;
}

.block-nota {
  display: block;
  margin-left: 12rem;
  border-width: 1px;
  border-style: solid;
  border-color: #000;
  border-radius: 8px;
  padding: 10px;
  width: auto;
  height: 100%;
}

.block-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}

.texto-normal {
  margin-left: 5px;
  margin-right: 5px;
}

.texto-bold {
  font-weight: bold;
}

.texto-underline {
  text-decoration: underline;
}

.border-around {
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 5px;
  padding: 5px;
}