.TimePicker {
    display: flex;
    margin: 2px;
    border-width: 1px;
    border-color: #aaa;
    border-radius: 4px;
    flex-direction: column;
    margin: auto;
}

.TimePicker button {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 0;
    padding-bottom: 0;
    margin: 3px;
}

.TimePicker .upperButtons {
    display: flex;
    justify-content: center;
}

.TimePicker .lowerButtons {
    display: flex;
    justify-content: center;
}

.TimePicker .values {
    display: flex;
    justify-content: center;
}
